import { getEvents, getRegistrations , login } from '@/api/Api';
import db from '@/firebase/init'
import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    Timestamp,
    query,
    where,
    deleteDoc,
} from "firebase/firestore";

import moment from 'moment';
import { defineStore } from 'pinia';

import { 
    bind, 
    DocumentProperties, 
    unbind 
} from 'pinia-firestore/packages/pinia-firestore/src/pinia-firestore';
import { useRouter } from 'vue-router';

type UserProperties = {
    name: string,
    id: number,
}
type EventProperties = {
    id: number;
    title: string;
    ts: Timestamp;
    visible?: boolean;
    categories: Array<{
        type: string
        value: string
    }>;
    type: string;
    stations?: Array<any>
}
type RegistrationProperties = {
    result: any;
    kennel: any;
    id: number;
    title: string;
    class: string;
    breed: string;
    chip: string;
    regnum: string;
    gender: string;
    ownerName: string;
    visible: boolean;
    paid: boolean;
    ts: Timestamp;
    sentTo: string | undefined;
    start_order: number | null;
}

export type TypeRegistration = RegistrationProperties & DocumentProperties;
export type TypeEvent = EventProperties & DocumentProperties;
export type TypeUser = UserProperties;

// @ts-ignore
const devNamespace = window.env.VUE_APP_NAMESPACE || process.env.VUE_APP_NAMESPACE;
const devEnv = devNamespace === 'dev'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: {} as TypeUser,
        endpoint: '',
    }),
    getters: {
        getEndpoint: (state) => state.endpoint,
        getUserID: (state) => {
            if (state.endpoint.includes('skk')) {
                return `${state.endpoint}User-${state.user.id}`;
            }
            
            return `kkUser-${state.user.id}`;
        },
        getCollectionPrefix: (state) => devEnv ? `${state.endpoint}_` : '',
    },
    actions: {
        async auth(
            formData: FormData,
            ep: string
        ): Promise<{ success: boolean, reason: string, user: unknown}> {

            const response = await login(formData);
            if (response.status === 200) {
                this.user = response.data.user
                this.endpoint = ep as string;
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('endpoint', this.endpoint)
                localStorage.setItem('jwtoken', response.data.token);

                return { success: true, reason: 'Succesfully logged in!', user: this.user };
            }
            return { success: false, reason: 'Could not login!', user: {} };
        },

        getUser() {
            if (localStorage.getItem('jwtoken') 
                && localStorage.getItem('user') 
                && localStorage.getItem('endpoint')
            ) {
                this.user = JSON.parse(localStorage.getItem('user') as string);
                this.endpoint = localStorage.getItem('endpoint') as string;

                return this;
            }

            console.error('User data corrupt, redirecting to login!');

            localStorage.removeItem('jwtoken');
            localStorage.removeItem('user');
            localStorage.removeItem('endpoint');

            const router = useRouter();
            router.push({
                name: 'auth'
            });

            return this;
        },

        destroy(): void {
            this.user = {} as TypeUser;
            this.endpoint = ''
            localStorage.removeItem('jwtoken');
            localStorage.removeItem('user');
            localStorage.removeItem('endpoint');
        }
    }
});

const range = () => {
    const getDaysOfMonth = (year: number, month: number) => new Date(year, month, 0).getDate()

    const today = new Date();
    const start = new Date();
    start.setMonth(today.getMonth() - 3);
    start.setDate(getDaysOfMonth(today.getFullYear(), today.getMonth()) - 3);

    const end = new Date();
    end.setMonth(today.getMonth() + 2);
    end.setDate(3);

    return {
        start,
        end
    }
};

const testtypes = [
    'bloodtracking',
    'chase',
    'chase_basset',
    'chase_tax',
    'chase_adb',
    'bird_skf',
    'bird_fa',
    'fa_highstatus',
    'working_test',
    'hunting_swk',
    'hunting_swk_hunting',
    'hunting_swk_forest',
    'hunting_retriever_a',
    'hunting_retriever_b',
    'hunting_retriever_function',
    'hunting_spaniel_vatten',
    'hunting_spaniel_nyborjare',
    'hunting_spaniel_falt',
    'hunting_spaniel_wt',
    'hunting_mock_trial',
    'hunting_tolling',
    'hunting_tolling_practical',
    'hunting_tjtk',
];

export const useEventStore = defineStore({
    id: 'events',
    state: () => ({
        events: [] as TypeEvent[],
        isLoading: false,
        synced: false,
        isSyncing: false,
    }),
    getters: {
        getEventByID: (state) => (eventId: string) => state.events.find((event) => event.id === eventId) as TypeEvent
    },
    actions: {
        async init(): Promise<void> {
            if (this.events.length) {
                return;
            }

            this.isLoading = true;
            const userStore = useUserStore().getUser();

            const dateRange = range();
            const q = query(collection(db, `${userStore.getCollectionPrefix}events`), 
                where('userId', '==', userStore.getUserID),
                where('visible', '==', true),
                where('ts', '>', dateRange.start),
                where('ts', '<', dateRange.end));

            await bind(this, 'events', q);

            this.events.forEach((e) => {
                useRegistrationsStore().prepareForOffline(e.path);
            })

            if (['kk', 'kk_dev', 'kk_test'].includes(userStore.getEndpoint)) {
                (await getDocs(collection(db, `${userStore.getCollectionPrefix}kennels`))).forEach((v) => v.data());
                (await getDocs(collection(db, `${userStore.getCollectionPrefix}categories`))).forEach((v) => v.data());
                (await getDocs(collection(db, `${userStore.getCollectionPrefix}breeds`))).forEach((v) => v.data());
                (await getDocs(collection(db, `${userStore.getCollectionPrefix}statuses`))).forEach((v) => v.data());
            }

            this.isLoading = false;
        },

        destroy(): void {
            unbind(this, 'events')
            this.events = [];
            this.isLoading = false;
            this.synced = false;
            this.isSyncing = false;
        },

        async sync(): Promise<void> {
            const user = useUserStore().getUser();

            if (user.endpoint.includes('skk')) {
                this.isSyncing = true
                // sync with skk-start
                const events = await getEvents();
                const registrations = await getRegistrations();

                if (events.status === 200 && registrations.status === 200) {
                    await this.hideFinishedEvents(events, user.endpoint, user.user.id);

                    await events.data.forEach(async (e: any) => {
                        const date = new Date(0)
                        date.setUTCSeconds(e.date.from);
                        const formattedDate = moment(date).format('YYYY-MM-DD');

                        const regs = registrations.data.filter((r: any) => r.eventId === e.id);

                        await this.addEvent(formattedDate, e.categories, e.type, user.endpoint as any, e.refereeStations, regs, e.title, e.id);
                    });
                    this.synced = true;
                }
                this.isSyncing = false;
            }
        },

        async hideFinishedEvents(
            events: any,
            ep: string,
            userId: number
        ): Promise<{ success: boolean, reason: string }> {

            const recivedIds = events.data.flatMap((revievedEvent: any) => ([`${ep}-${userId}-${revievedEvent.id}`]))
            const userStore = useUserStore().getUser();

            const q = query(collection(db, `${userStore.getCollectionPrefix}events`), 
                            where('userId', '==', userStore.getUserID));
            const eventDocs = await getDocs(q);

            eventDocs.forEach(async (v) => {
                const d = v.data();
                if (recivedIds.includes(d.id)) {
                    return;
                }
                if (!d.visible) {
                    return;
                }

                await this.hideEvent(d.id, userStore);
            });

            return { success: true, reason: "" };
        },

        async hideEvent(
            eventId: string,
            userStore: any
        ): Promise<void> {
            const docRef = doc(db, `${userStore.getCollectionPrefix}events`, eventId);
            updateDoc(docRef, {
                visible: false,
            });
            // await deleteDoc(docRef);
        },

        async addEvent(
            date: string, 
            categories: unknown[], 
            type: string,
            ep: 'kk' | 'skktest' | 'skk' | 'skklocal' | 'skkdev',
            stations?: unknown[],
            registrations?: unknown[], 
            title?: string,
            eventId?: number
        ): Promise<{ success: boolean, reason: string }> {

            const userStore = useUserStore().getUser()
            if (!userStore.user || date === '') {
                return { success: false, reason: 'Kunde inte hitta planeingsdag/användare'};
            }

            const eventName = title || `${userStore.user.name} - ${date}`;
            const id = `${ep}-${userStore.user.id}-${eventId || date}`;
            const snap = await getDoc(doc(db, `${userStore.getCollectionPrefix}events`, id)).catch((err) => console.log(err));

            if (!snap) {
                return { success: false, reason: 'Kunde inte hämta data' };
            }

            if (!snap.exists()) {
                const eventData = {
                    categories,
                    id,
                    title: eventName,
                    visible: true,
                    ts: Timestamp.fromDate(new Date(date)),
                    type,
                    stations: stations || [],
                    userId: `${ep}User-${userStore.user.id}`
                };

                await setDoc(snap.ref, eventData);

                await this.hideFinishedRegs(id, registrations);

                if (type === 'kennelvisit') {
                    registrations?.forEach(async (kennel: any) => {
                        const reg_id = doc(collection(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations`)).id
                        const registrationData = {
                            id: reg_id,
                            kennel: doc(db, `${userStore.getCollectionPrefix}kennels/${kennel.id}`),
                            visible: true,
                            title: `Besök ${kennel.name}`,
                            ts: Timestamp.fromDate(new Date(date)),
                        }

                        await setDoc(doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations`, reg_id), registrationData);
                    });
                } 
                else if (testtypes.includes(type)) {
                    registrations?.forEach(async (reg: any) => {
                        const registrationData = {
                            result: reg.results || null,
                            breed: reg.competitor.metadata.breed || null,
                            chip: reg.competitor.metadata.chip || null,
                            gender: reg.competitor.metadata.gender || null,
                            regnum: reg.competitor.metadata.regId || null,
                            ownerName: `${reg.competitor.metadata.owner.firstname || '-'} ${reg.competitor.metadata.owner.lastname || '-'}`,
                            visible: true,
                            title: reg.competitor.name || null,
                            paid: reg.payment.paid || null,
                            ts: Timestamp.fromMillis(reg.scheduled_at || 0),
                            class: reg.class || null,
                            id: reg.id || null,
                            start_order: reg.start_order ?? null,
                        }

                        if (Object.prototype.hasOwnProperty.call(reg, 'bestAftersearch') && Array.isArray(reg.bestAftersearch) && reg.bestAftersearch.length) {
                            const evalRef = doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/${reg.id}/results/evaluation`);
                            const evalDoc = await getDoc(evalRef);
                            const provform = categories.find((v: any) => v.type === 'Provform') as any
    
                            if (!evalDoc.exists() && ['Fält', 'Fjäll', 'Skog'].includes(provform?.value)) {
                                const addData = new Map<string, any>;
                                reg.bestAftersearch.filter((d: any) => d.type !== 'undefined').forEach((v: any) => {
                                    addData.set((v as any).type, v);
                                });
                    
                                const dataObj = Object.fromEntries(addData);
    
                                await setDoc(evalRef, dataObj);
                            }
                        }

                        await setDoc(doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations`, reg.id), registrationData);
                    });
                }
                return { success: true, reason: 'Event sparat!' };

            }
            
            if (testtypes.includes(type) && snap.exists()) {
                const dateRange = range();
                const eventDate = new Date(date);

                if (eventDate <= dateRange.start ||
                    eventDate >= dateRange.end) {

                    return { success: true, reason: '' };
                }

                const eventData = {
                    categories,
                    id,
                    title: eventName,
                    ts: Timestamp.fromDate(new Date(date)),
                    visible: true,
                    type,
                    stations: stations || [],
                    userId: `${ep}User-${userStore.user.id}`
                };
                await updateDoc(snap.ref, eventData);

                await this.hideFinishedRegs(snap.id, registrations);

                const regDocs = (await getDocs(collection(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/`))).docs;

                // const compareArrays = (a: any, b: any) =>
                //     a.length === b.length &&
                //     a.every((element: any, index: number) => element === b[index]);

                // const reIds = registrations?.map((v: any) => v.id);
                // const reDocIds = regDocs.map((v) => v.data().id);

                // if (compareArrays(reIds, reDocIds)) {
                //     return { success: true, reason: 'Updated Event info!'}
                // }

                registrations?.forEach(async (reg: any) => {
                    const regPath = doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/${reg.id}`);

                    const data = {
                        result: reg.results || null,
                        breed: reg.competitor.metadata.breed || null,
                        chip: reg.competitor.metadata.chip || null,
                        gender: reg.competitor.metadata.gender || null,
                        regnum: reg.competitor.metadata.regId || null,
                        ownerName: `${reg.competitor.metadata.owner.firstname || '-'} ${reg.competitor.metadata.owner.lastname || '-'}`,
                        title: reg.competitor.name || null,
                        paid: reg.payment.paid || null,
                        visible: true,
                        ts: Timestamp.fromMillis(reg.scheduled_at || 0),
                        class: reg.class || null,
                        id: reg.id || null,
                        start_order: reg.start_order ?? null,
                    }

                    if (Object.prototype.hasOwnProperty.call(reg, 'bestAftersearch') && Array.isArray(reg.bestAftersearch) && reg.bestAftersearch.length) {
                        const evalRef = doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/${reg.id}/results/evaluation`);
                        const evalDoc = await getDoc(evalRef);
                        const provform = categories.find((v: any) => v.type === 'Provform') as any

                        if (!evalDoc.exists() && ['Fält', 'Fjäll', 'Skog'].includes(provform?.value)) {
                            const addData = new Map<string, any>;
                            reg.bestAftersearch.filter((d: any) => d.type !== 'undefined').forEach((v: any) => {
                                addData.set((v as any).type, v);
                            });
                
                            const dataObj = Object.fromEntries(addData);

                            await setDoc(evalRef, dataObj);
                        }
                    }

                    if (regDocs.findIndex((v) => v.data().id === data.id) !== -1) {
                        await updateDoc(regPath, data);
                        return;
                    }

                    await setDoc(regPath, data);
                });
                return { success: true, reason: 'Updated Event info!' };

            }
            return { success: false, reason: 'Kennelbesök kunde inte sparas!' };
        },

        async hideFinishedRegs(
            id: string,
            registrations?: unknown[],
        ): Promise<void> {
            if (!registrations) {
                return;
            }

            if (!registrations.length) {
                return
            }

            const userStore = useUserStore().getUser()
            const regs = await getDocs(collection(db, `${userStore.getCollectionPrefix}events/${id}/registrations`)).catch(console.log);
            const recivedIds = registrations.flatMap((v: any) => v.id);

            if (!regs) {
                return;
            }

            await regs.forEach(async (snap) => {
                if (!snap.exists()) {
                    return;
                }

                const data = snap.data();
                if (recivedIds.includes(data.id)) {
                    return;
                }

                if (!data.visible) {
                    return;
                }

                await this.hideReg(id, snap.id, userStore);
            });
        },

        async hideReg(id: string, regId: string, userStore: any): Promise<void> {
            const docRef = doc(db, `${userStore.getCollectionPrefix}events/${id}/registrations/${regId}`);

            updateDoc(docRef, {
                visible: false,
            });
        }
    }
});

export const useRegistrationsStore = defineStore({
    id: 'registrations',
    state: () => ({
        registrations: [] as TypeRegistration[],
    }),
    getters: {
        getRegistrationByID: (state) => (registrationID: (string)) => state.registrations.find((reg) => reg.id === registrationID) as TypeRegistration
    },
    actions: {
        async prepareForOffline(eventPath: string) {
            const regs = await getDocs(collection(db, `${eventPath}/registrations/`));
            await regs.forEach(async (r: any) => {
                r.data();
                await useEvaluationsStore().prepareForOffline(`${eventPath}/registrations/${r.id}`);
            })
        },

        async init(
            event: TypeEvent
        ): Promise<void> {

            if (event.type !== 'kennelvisit') {
                await bind(this, 'registrations', collection(db, `${event.path}/registrations/`));
                return;
            }
            
            this.registrations = [];

            (await getDocs(collection(db, `${event.path}/registrations/`))).forEach(
                (r) => {
                    const data = r.data();
                    this.registrations.push(
                        {
                            ...data as any,
                            id: r.id,
                            path: r.ref.path,
                            metadata: r.metadata
                        } as TypeRegistration
                    )

                }
            );
        },

        async getKennelData(registration: TypeRegistration): Promise<TypeRegistration> {
            if (!registration.kennel) {
                return registration;
            }

            const userStore = useUserStore().getUser()
            if (!userStore.user) {
                return registration;
            }

            let kennelDocPath = registration.kennel.path;
            if (!kennelDocPath && registration.kennel) {
                kennelDocPath = `${userStore.getCollectionPrefix}kennels/${registration.kennel}`
            }

            const kennelSnap = await getDoc(doc(db, kennelDocPath));
            const kennelRet = { ...registration };

            if (kennelSnap.exists()) {
                const data = kennelSnap.data();
                kennelRet.kennel = data;
            }

            if (kennelRet.kennel.breeds.length) {
                const promises = kennelRet.kennel.breeds.map((breed: any) => getDoc(doc(db, breed.path)));
                try {
                    const filtered = await Promise.all(promises).then((ret) =>
                        ret.filter((r) => r.exists()).map((f) => f.data())
                    )
                    kennelRet.kennel.breeds = filtered;
                } catch (error) {
                    console.error(error);
                }
            }

            if (kennelRet.kennel.litters.length) {
                for (let i = 0; i < kennelRet.kennel.litters.length; i++) {
                    const litter = kennelRet.kennel.litters[i];

                    const r = await getDoc(doc(db, litter.breed.path))
                    if (r.exists()) {
                        litter.breed = r.data();
                    }

                }
            }

            return kennelRet
        },

        destroy(): void {
            const userStore = useUserStore().getUser();
            if (!['kk', 'kk_dev', 'kk_test'].includes(userStore.getEndpoint)) {
                unbind(this, 'registrations');
            }
            this.registrations = [];
        },

        async removeRegistration(
            regId: string
        ): Promise<{ success: boolean, reason: string }> {

            if (!this.registrations.length) {
                return { success: false, reason: 'Kan inte hitta några registreringar!' };;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}`);
            if (!docRef) {
                return { success: false, reason: 'Kan inte hitta registreringen!' };
            }

            deleteDoc(docRef);
            return { success: true, reason: 'Registrering bort tagen!' };
        },

        async addRegistration(
            event: TypeEvent, 
            registrations: any[]
        ): Promise<{ success: boolean, reason: string }> {

            if (!this.registrations.length) {
                return { success: false, reason: 'Kunde inte hitta nuvarande besök!'};
            }

            const userStore = useUserStore().getUser();
            if (!userStore.user) {
                return { success: false, reason: 'Kunde inte hitta användare'};
            }
            const existingRegsIds = this.registrations.map((reg) => reg.kennel.id);
            const unsaveableRegs = [] as any;

            const date = new Date(0);
            date.setUTCSeconds(event.ts.seconds);

            const {id} = event;
            const snap = await getDoc(doc(db, `${userStore.getCollectionPrefix}events`, id)).catch(console.log);

            if (!snap) {
                return { success: false, reason: 'Kunde inte skapa nytt event'};
            }

            registrations.forEach(async (kennel) => {
                if (existingRegsIds.includes(kennel.id)) {
                    unsaveableRegs.push(kennel.name);
                    return;
                }

                const reg_id = doc(collection(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations`)).id
                const registrationData = {
                    id: reg_id,
                    kennel: kennel.id,
                    title: `Besök ${kennel.name}`,
                    ts: event.ts,
                }

                await setDoc(doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/${reg_id}`), registrationData);
            });

            if (unsaveableRegs.length >= registrations.length) {
                return { success: false, reason: 'Alla besök finns redan inlaggda på den här dagen' };
            }

            if (unsaveableRegs.length) {
                const usRegsString = unsaveableRegs.join(', ');
                return { success: true, reason: `Alla besök sparade förutom ${usRegsString}` };
            }

            return { success: true, reason: 'Alla besök sparade' };
        },

        async addResult(
            regId: string, 
            type: string, 
            data: Array<unknown>,
            nameKey?: string,
            mode?: 'set' | 'update',
        ): Promise<void> {
            if (!this.registrations.length) {
                console.error('Registrations is empty!');
                return;
            }

            const colResults = collection(db, `${this.getRegistrationByID(regId).path}/results/`);
            const docRef = doc(db, `${colResults.path}/${type}`);
            const docSnap = await getDoc(docRef).catch(console.log);

            const addData = new Map<string, any>;
            data.filter((d: any) => d.type !== 'undefined').forEach((v) => {
                if (nameKey) {
                    addData.set((v as any)[nameKey], v);
                    return;
                }
                addData.set((v as any).type, v);
            });

            const dataObj = Object.fromEntries(addData);

            if (docSnap && docSnap.exists() && mode !== 'set') {
                updateDoc(docRef, dataObj);
            } else {
                setDoc(docRef, dataObj)
            }
        },

        setPaidStatus(
            regId: string,
            value: boolean
        ): void {
            if (!this.registrations.length) {
                return;
            }

            const docRef = doc(db, this.getRegistrationByID(regId).path)
            updateDoc(docRef, { paid: value });
        },

        async MarkDone(
            regId: string, 
            type: string
        ): Promise<void> {

            if (!this.registrations.length) {
                return;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}/results/${type}`);
            const docSnap = await getDoc(docRef).catch(console.log);

            if (docSnap && docSnap.exists()) {
                updateDoc(docRef, { done: true }).catch((r) => console.log(r));
            } else {
                setDoc(docRef, { done: true }).catch((r) => console.log(r));
            }
        },

        async MarkIncomplete(
            regId: string, 
            type: string
        ): Promise<void> {
            if (!this.registrations.length) {
                return;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}/results/${type}`);
            const docSnap = await getDoc(docRef).catch(console.log);

            if (docSnap && docSnap.exists()) {
                updateDoc(docRef, { done: false }).catch((r) => console.log(r));
            } else {
                setDoc(docRef, { done: false }).catch((r) => console.log(r));
            }
        },

        async isEvaluationDone(
            regId: string, 
            type: string
        ): Promise<boolean> {
            if (!this.registrations.length) {
                return false;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}/results/${type}`);
            const docSnap = await getDoc(docRef).catch(console.log);

            if (docSnap && docSnap.exists()) {
                const isDone = await docSnap.get('done');
                if (isDone !== undefined) {
                    return isDone;
                }
                return false;
            }
            return false;
        },

        MarkSent(
            regId: string,
            link: string
        ): void {
            if (!this.registrations.length) {
                return;
            }

            const docRef = doc(db, this.getRegistrationByID(regId).path);
            updateDoc(docRef, { sentTo: link });
        },

        async markFollowUp(
            regId: string,
            data: Array<Object>
        ): Promise<void> {
            const userStore = useUserStore().getUser();
            const reg = this.getRegistrationByID(regId);
            const kennelRef = doc(db, `${userStore.getCollectionPrefix}kennels/${reg.kennel.id}`);

            const needFollowUp = data.filter((d: any) => d.type === 'need_follow_up')[0] as any;
            const needFollowUpWhen = data.filter((d: any) => d.type === 'need_follow_up_when')[0] as any;

            if (needFollowUp.value && needFollowUpWhen.value) {
                updateDoc(kennelRef, {
                    'status': doc(db, `${userStore.getCollectionPrefix}statuses/${needFollowUp.value === 'yes' ? '5' : '3'}`),
                    'visit.revisit_latest': Timestamp.fromMillis(needFollowUpWhen.value)
                });
            }
        },

        async markVisitComplete(
            regId: string, 
            mark: 'yes' | 'no'
        ): Promise<void> {
            const reg = this.getRegistrationByID(regId);
            const userStore = useUserStore().getUser();

            const kennelMark = mark === 'yes' ? 'completed' : 'completed_failed';
            const { kennel } = reg;
            const kennelRef = doc(db, `${userStore.getCollectionPrefix}kennels/${kennel.id}`);

            updateDoc(doc(db, reg.path), { done: { mark } });
            updateDoc(kennelRef, {
                "visit.booked": null,
                "visit.booked_by": null,
                "visit.last_status": kennelMark,
                "visit.last": kennel.visit.booked ? kennel.visit.booked : kennel.visit.last,
                "visit.last_by": kennel.visit.booked_by ? kennel.visit.booked_by : kennel.visit.last_by
            });
        },

        async getHasResult(
            regId: string, 
            type: string
        ): Promise< -1 | 0 | 1 > {

            if (!this.registrations.length) {
                return -1;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}/results/${type}`);
            if (!docRef) {
                return -1;
            }
            const docSnap = await getDoc(docRef).catch(console.log);

            if (docSnap && docSnap.exists()) {
                const data = docSnap.data();

                if (data.control_result) {
                    if (data.control_result.controll_status === 'not_ok') {
                        return 0;
                    }
                }

                const done = data.done ? 1 : 0;

                return done;
            }
            return -1;
        },

        async getResultData(
            regId: string, 
            type: string
        ): Promise<unknown|null> {
            if (!this.registrations.length) {
                return null;
            }

            const docRef = doc(db, `${this.getRegistrationByID(regId).path}/results/${type}`);
            if (!docRef) {
                return null;
            }

            const docSnap = await getDoc(docRef).catch(console.log);

            if (docSnap && docSnap.exists()) {
                const data = docSnap.data();
                return data;
            }
            return null;          
        }
    }
});

export const useEvaluationsStore = defineStore({
    id: 'evaluations',
    state: () => ({
        evaluations: [] as any[],
        initialized: false,
    }),
    getters: {
    },
    actions: {
        async prepareForOffline(registrationPath: string) {
            const evals = await getDocs(collection(db, `${registrationPath}/results/`));
            evals.forEach((e: any) => {
                e.data();
            })
        },

        async init(
            registration: TypeRegistration
        ): Promise<void> {
            if (!Object.prototype.hasOwnProperty.call(registration, 'path')) {
                return;
            }
            
            await bind(this, 'evaluations', collection(db, `${registration.path}/results/`));
            this.initialized = true;
        },

        isNoShow(evalDocName: string, kennelvisitNoShow = false) {
            if (!this.evaluations.length) {
                return {};
            }

            if (kennelvisitNoShow) {
                const intruduction = this.evaluations.find((v) => v.id === 'introduction')

                if (!intruduction) {
                    return { no_show: false, hideRevisit: false };
                }

                const { visit_type } = intruduction
                
                if (visit_type && visit_type.value === 'revisit_remotely') {
                    return { no_show: true, hideRevisit: false };
                }
                
                const { member_home } = intruduction
                if (!member_home) {
                    return { no_show: false, hideRevisit: false };
                }

                return {
                    no_show: ['no', 'member_has_no_time', 'member_dont_want_visitors', 'member_other'].includes(member_home.value),
                    hideRevisit: true
                }
            }

            const evaluation = this.evaluations.find((v) => v.id === evalDocName);
            if (!evaluation) {
                return false;
            }
            
            const { no_show } = evaluation;
            if (!no_show) {
                return false;
            }

            return Boolean(no_show.value);
        },

        destroy(): void {
            unbind(this, 'evaluations');
            this.evaluations = [];
        }
    }
});

export const useBreedStore = defineStore({
    id: 'breeds',
    state: () => ({
        breeds: [] as any[],
        initialized: false,
    }),
    actions: {
        async init() {
            const userStore = useUserStore().getUser();
            await bind(this, 'breeds', collection(db, `${userStore.getCollectionPrefix}breeds`));
            this.initialized = true;
        },

        async getKennelBreeds(
            registration: TypeRegistration
        ): Promise<Array<{ id: number, name: string }>> {
            const { kennel } = registration;
            const userStore = useUserStore().getUser();
            let { breeds } = kennel;

            if (breeds === undefined) {
                let kennelDocPath = kennel.path;
                if (!kennelDocPath) {
                    kennelDocPath = `${userStore.getCollectionPrefix}kennels/${kennel}`
                }
                let kDoc = null;

                try {
                    kDoc = await getDoc(doc(db, kennelDocPath));
                } catch (error) {
                    console.log(error);
                }

                if (!kDoc) {
                    return [];
                }

                const kData = kDoc.data() as any;

                kData?.breeds.forEach((breed: { path: string }, idx: number) => {
                    kData.breeds[idx] = { ...this.breeds.find((fBreed: { path: string }) => fBreed.path === breed.path) };
                });

                breeds = kData.breeds.map((b: any) => ({
                    id: parseInt(b.id, 10),
                    name: b.name
                }));
            }

            return breeds;
        }
    }
})
