<template>
    <div>
        <h3>Summering</h3>
        <div>{{ calculatedValue }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

function merge(a: Array<any>, b: Array<any>, prop: string) {
    const reduced = a.filter((aitem) => !b.find((bitem) => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

const include = [
    'search',
    'fetch_work',
    'chase_safety',
    'chase_manner',
    'nosework',
    'bark',
    'bark_mark',
    'cooperation',
    'obedience',
    'hunting_skills'
]

export default defineComponent({
    name: 'SummeriseSWKSums',
    props: {
        values: { 
            type: Array,
            required: true,
        },
        changes: {
            type: Array,
            required: true,
        },
        evaluations: {
            type: Array,
            required: true,
        }
    },
    emits: ['change'],
    computed: {
        calculatedValue() {
            const merged = merge(this.values, this.changes, 'type')

            if (!merged) {
                return 0;
            }
            
            const vals = merged
                .filter((val) => include.includes(val.type))
                .map((val) => val.value)
                .filter((v) => v >= 0);

            return Math.max(0, vals.reduce((cur, next) => cur + next, 0));
        }
    },
    watch: {
        calculatedValue(newVal) {
            this.$emit('change', [{
                type: 'summery_tax_chase',
                value: newVal,
                value_text: `${newVal}`,
                comment: '',
                scope: null,
                dynamic: null
            }]);
        }
    },
})

</script>
