<template>
    <v-container
        v-if="!isLoading && evaluation"
        class="px-8 py-8 relative"
    >
        <div class="form-head-sticky">
            <div
                v-if="registration && registration.title"
                class="form-head"
            >
                <div class="d-flex flex-column">
                    <span class="text-body-1"><strong>{{ registration.title }}</strong></span>
                    <div class="text-body-2">
                        <span>
                            Ägare:
                            <strong>{{ registration.ownerName }}</strong>
                        </span>
                        <div
                            v-if="registration.class && registration.ts"
                            class="d-flex flex-wrap justify-space-between"
                        >
                            <span>
                                Klass:
                                <strong>{{ registration.class }}</strong>
                            </span>
                            <span>
                                Datum:
                                <strong>{{ registrationDate }}</strong>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-3 mx-4">
                <v-alert
                    v-model="showError"
                    border="start"
                    position="sticky"
                    closable
                    variant="flat"
                    type="warning"
                    close-label="Close Alert"
                    elevation="3"
                    class="full-width mb-4"
                >
                    <span>
                        De värden som du har fyllt i har sparats!<br>
                        Observera att värden saknas för:
                    </span>
                
                    {{ errorText }}
                </v-alert>
            </div>
        </div>

        <div>
            <v-row
                v-for="scope in evaluation"
                v-show="checkScopeRequirements(scope)"
                :key="scope.scope"
                ripple
                class="cursor-pointer evaluation-section"
            >
                <v-col align="left">
                    <div
                        v-if="!$route.params.extraId && scope.title"
                        id="tooltip"
                        class="section-title"
                    >
                        <div class="title">
                            {{ scope.title }}
                        </div>

                        <v-dialog 
                            v-if="scope.tooltip"
                            v-model="scope.showDialog"
                            class="tooltip" 
                            max-width="600px"
                        >
                            <template #activator="{ props }">
                                <v-btn
                                    icon
                                    class="mb-0"
                                    v-bind="props"
                                    flat
                                >
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline">
                                    <slot name="title">
                                        {{ scope.title }}
                                    </slot>
                                </v-card-title>

                                <v-card-text
                                    v-for="s in (scope.tooltip).split('\n')"
                                    :key="s"
                                    class="headline"
                                >
                                    <slot name="text">
                                        {{ s }}
                                    </slot>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="info"
                                        @click="scope.showDialog = false"
                                    >
                                        Stäng
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>

                    <evaluation-inputs
                        v-if="scope"
                        ref="evaluation"
                        :inputs="scope.inputs"
                        :registration="registration"
                        :all-changes="evaluationChanges"
                        :values="values"
                        :disable="no_show_local"
                        @change="onChange"
                    />
                </v-col>
            </v-row>
            <v-row v-if="summeriseType">
                <v-col>
                    <component
                        :is="summeriseType"
                        :values="[...values || []]"
                        :changes="[...evaluationChanges || []]"
                        :evaluations="evaluation"
                        @change="onChange"
                    />
                </v-col>
            </v-row>
        </div>

        <v-row class="ma-0 pa-0 pt-8">
            <v-col class="ma-0 pa-0">
                <v-list-item-content>
                    <v-btn
                        color="success"
                        block
                        @click.prevent="saveChanges"
                    >
                        Spara
                    </v-btn>

                    <v-btn
                        style="margin-top: 20px"
                        color="info"
                        block
                        @click="$router.push({ name: 'RegistrationView' })"
                    >
                        Stäng
                    </v-btn>
                </v-list-item-content>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-else
        class="layout-container-fullscreen layout-padding-top"
    >
        <v-progress-circular
            indeterminate
            color="var(--green-3)"
            size="150"
            width="20"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import EvaluationInputs from '@/components/EvaluationInput.vue';
import SummeriseSWKSums from '@/components/data-handlers/SummeriseSWKSums.vue';
import SummeriseChaseTax from '@/components/data-handlers/SummeriseChaseTax.vue';
import { 
    TypeEvent, 
    useEventStore,
    TypeRegistration, 
    useRegistrationsStore,
    useEvaluationsStore,
} from '@/stores/store';

import skf from '@/assets/jsonnet/skf.jsonnet';
import fa from '@/assets/jsonnet/fa.jsonnet';
import chase from '@/assets/jsonnet/chase.jsonnet';
import taxchase from '@/assets/jsonnet/taxchase.jsonnet';
import adbchase from '@/assets/jsonnet/adbchase.jsonnet';
import bassetchase from '@/assets/jsonnet/bassetchase.jsonnet';
import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet';
import kennelvisit from '@/assets/jsonnet/kennelvisit.jsonnet';
import workingtest from '@/assets/jsonnet/workingtest.jsonnet';
import huntingretriever from '@/assets/jsonnet/ssrk.jsonnet';
import hunting_swk from '@/assets/jsonnet/hunting_swk.jsonnet';
import hunting_swk_hunting from '@/assets/jsonnet/hunting_swk_hunting.jsonnet';
import hunting_swk_forest from '@/assets/jsonnet/hunting_swk_forest.jsonnet';
import huntingspaniel from '@/assets/jsonnet/ssrkspan.jsonnet';
import hunting_mock from '@/assets/jsonnet/huntingmock.jsonnet';
import huntingfunction from '@/assets/jsonnet/ssrkfunction.jsonnet';
import hunting_tolling from '@/assets/jsonnet/huntingtolling.jsonnet';
import hunting_tolling_practical from '@/assets/jsonnet/huntingtolling_practical.jsonnet';

import skk from '@/components/shared/translations/skk.json'

export default defineComponent({
    name: 'EvaluationView',
    components: {
        EvaluationInputs,
        'swk': SummeriseSWKSums,
        'chase_tax': SummeriseChaseTax
    },
    data: () => ({
        isLoading: false,
        showdialog: [],
        showModal: false,
        selectedScope: 0,
        rawCompetitor: {},
        evaluation: [] as any,
        values: [] as any,
        categories: {},
        events: useEventStore(),
        registrations: useRegistrationsStore(),
        evaluations: useEvaluationsStore(),
        evaluationChanges: [] as Object[],
        evaluationLoading: false,
        showError: false,
        errorText: '',
        no_show_local: 0,
        compiledFunctions: [] as any[],
        currentType: '',
        summeriseType: null as String|null,
    }),
    computed: {
        event() {
            return this.events.getEventByID(this.$route.params.eventId as string);
        },

        registration() {
            return this.registrations.getRegistrationByID(this.$route.params.regId as string);
        },

        registrationClasses() {
            if (!this.registration.class) {
                return '';
            }

            return this.registration.class
        },

        registrationDate() {
            const locale = 'sv-SE'
            const options = { 
                month: 'short', 
                year: 'numeric', 
                day: 'numeric'
            } as Intl.DateTimeFormatOptions;

            const date = this.registration.ts.toDate();

            if (date.getHours() === 1) {
                return `${date.toLocaleDateString(locale, options)} Heldag`
            }

            const timeAdditions = {
                hour: '2-digit',
                minute: '2-digit'
            } as Intl.DateTimeFormatOptions;

            return date.toLocaleDateString(locale, {
                ...options, 
                ...timeAdditions
            });
        },

        no_show() {
            if (this.evaluations.evaluations.length) {
                const evaluation = this.evaluations.evaluations.find((v: any) => v.id === 'evaluation');
                if (evaluation) {
                    const { no_show } = evaluation;
                    if (no_show) {
                        return no_show.value
                    }
                }
            }
            return 0;
        }
    },
    async mounted() {
        this.isLoading = true;
        this.currentType = this.$route.params.type.toString();


        // If we dont know type send us back to last page we visited
        if (this.currentType === '') {
            this.$router.back();
        }

        await this.events.init();
        if (!this.registrations.registrations.length) {
            await this.registrations.init(this.event as TypeEvent);
        }
        
        await this.evaluations.init(this.registration as TypeRegistration);
        this.values = this.getValues();

        if (this.currentType === 'moment' && this.event.type === 'hunting_swk_hunting') {
            this.summeriseType = 'swk';
        } else if (this.currentType === 'registration' && this.event.type === 'chase_tax') {
            this.summeriseType = 'chase_tax';
        }

        switch (this.event.type) {
            case 'bird_skf':
                switch (this.registration.breed) {
                    case 'BRAQUE FRANCAIS, TYPE PYRÉNÉES':
                    case 'EPAGNEUL BRETON':
                    case 'BRETON':
                        this.evaluation = skf.breton[this.currentType];
                        break;
                    case 'BRACCO ITALIANO':
                    case 'SPINONE ITALIANO':
                    case 'SPINONE':
                        if (this.registration.class.includes('EKL')) {
                            this.evaluation = skf.brac_spin_ekl[this.currentType as string];
                            break;
                        }
                        this.evaluation = skf.brac_spin[this.currentType];
                        break;
                    case 'SLOVENSKÝ HRUBOSRSTY STAVAC (OHAR)':
                    case 'GRIFFON D\'ARRET À POIL DUR/KORTHALS':
                    case 'GRIFFON D\'ARRET A POIL DUR':
                    case 'WEIMARANER, KORTHÅRIG':
                    case 'WEIMARANER KURZHAARIG':
                    case 'WEIMARANER, LÅNGHÅRIG':
                    case 'WEIMARANER LANGHAARIG':
                        if (this.registration.class.includes('EKL') && this.event.categories.findIndex((eventEntry) => eventEntry.value.includes('Internationellt')) !== -1) {
                            this.evaluation = skf.grif_weim_srhp_ekl_int[this.currentType];
                            break;
                        } else if (this.registration.class.includes('EKL') && this.event.categories.findIndex((eventEntry) => eventEntry.value.includes('Nationellt')) !== -1) {
                            this.evaluation = skf.grif_weim_srhp_ekl_nat[this.currentType];
                            break;
                        }
                        this.evaluation = skf.grif_weim_srhp[this.currentType];
                        break;
                    default:
                        if (this.registration.class.includes('EKL')) {
                            this.evaluation = skf.drent_gdh_stab_other_ekl[this.currentType];
                            break;
                        }
                        this.evaluation = skf.drent_gdh_stab_other[this.currentType];
                        break;
                }
                break;
            case 'bird_fa':
                switch (this.registration.class) {
                    case 'SKL endast Breton':
                    case 'SKL':
                        this.evaluation = fa.standard.skl[this.currentType];
                        break;
                    case 'UKL 9-24 mån': 
                        this.evaluation = fa.standard.ukl[this.currentType];
                        break;
                    case 'Apport':
                        this.evaluation = fa.standard.apport[this.currentType];
                        break;
                    case 'ÖKL över 9 mån':
                    default: 
                        this.evaluation = fa.standard.okl[this.currentType];
                        break;
                }
                break;
            case 'fa_highstatus':
                switch (this.registration.class) {
                    case 'SKL Internationell':
                    case 'SKL endast Breton':
                    case 'SKL':
                        this.evaluation = fa.highstatus.skl[this.currentType];
                        break;
                    case 'UKL 9-24 mån': 
                    default: 
                        this.evaluation = fa.highstatus.ukl[this.currentType];
                        break;
                }
                break;
            case 'hunting_retriever_a':
                const test_type = this.event.categories.find((category) => category.type === 'Provslag')?.value;

                if (test_type === 'Nationellt') {
                    switch (this.registration.class) {
                        case 'ÖKL':
                            this.evaluation = huntingretriever.type_a.okl_nat[this.currentType];
                            break;
                        case 'EKL':
                            this.evaluation = huntingretriever.type_a.ekl_nat[this.currentType];
                            break;
                        default:
                            this.evaluation = huntingretriever.type_a.okl_nat[this.currentType];
                            break;
                    }
                } else {
                    this.evaluation = huntingretriever.type_a.ekl_int[this.currentType];
                }
                break;
            case 'hunting_retriever_b':
                this.evaluation = huntingretriever.type_b.evaluation;
                break;
            case 'hunting_spaniel_vatten':
                this.evaluation = huntingspaniel.water.evaluation;
                break;
            case 'hunting_spaniel_nyborjare':
                this.evaluation = huntingspaniel.beginner.evaluation;
                break;
            case 'hunting_spaniel_falt':
                switch (this.registration.class) {
                    case 'Öppenklass Enkelsläpp':
                    case 'Öppenklass Parsläpp':
                        this.evaluation = huntingspaniel.okl.evaluation;
                        break;
                    case 'Segrarklass Enkelsläpp':
                    case 'Segrarklass Parsläpp':
                        this.evaluation = huntingspaniel.skl.evaluation;
                        break;
                    default:
                        this.evaluation = huntingspaniel.skl.evaluation;
                        break;
                }
                break;
            case 'hunting_spaniel_wt':
                this.evaluation = huntingspaniel.working.evaluation;
                break;
            case 'bloodtracking':
                this.evaluation = bloodtracking.evaluation[this.currentType];
                break;
            case 'chase':
                this.evaluation = chase.evaluation[this.currentType];
                break;
            case 'chase_tax':
                this.evaluation = taxchase.evaluation[this.currentType];
                break;
            case 'chase_basset':
                this.evaluation = bassetchase.evaluation[this.currentType];
                break;
            case 'chase_adb':
                this.evaluation = adbchase.evaluation[this.currentType];
                break;
            case 'kennelvisit':
                this.evaluation = kennelvisit[this.currentType];
                break;
            case 'working_test':
                this.evaluation = workingtest.evaluations;
                break;
            case 'hunting_tjtk':
            case 'hunting_swk':
                this.evaluation = hunting_swk[this.currentType];
                break;
            case 'hunting_swk_hunting':
                await this.evaluations.init(this.registration as TypeRegistration);
                this.values = this.getValues();

                switch (this.registration.class) {
                    case 'Öppen klass':
                        this.evaluation = hunting_swk_hunting.okl[this.currentType];
                        break;
                    default: 
                        this.evaluation = hunting_swk_hunting.other[this.currentType];
                        break;
                }
                break;
            case 'hunting_swk_forest':
                this.evaluation = hunting_swk_forest[this.currentType];
                break;
            case 'hunting_retriever_function':
                this.evaluation = huntingfunction[this.currentType];
                break;
            case 'hunting_mock_trial':
                this.evaluation = hunting_mock[this.currentType];
                break;
            case 'hunting_tolling':
                this.evaluation = hunting_tolling[this.currentType];
                break;
            case 'hunting_tolling_practical':
                this.evaluation = hunting_tolling_practical[this.currentType];
                break;
            default:
                this.values = null;
                this.evaluation = null;
                break;
        }

        this.no_show_local = this.no_show;

        this.isLoading = false;
    },
    methods: {

        onChange(changes: Array<Object>) {
            function newestUniq(a: Array<Object>) {
                const index = {} as any;
                // @ts-ignore
                a.forEach((item: any) => {
                    index[item.type] = item
                });
                return Object.values(index) as Array<Object>;
            }

            // Sends an event through so need to check if array before saving
            if (Array.isArray(changes)) {
                changes.forEach((e) => {
                    this.evaluationChanges.push(e);
                });
                this.evaluationChanges = newestUniq(this.evaluationChanges);

                // this.checkScopeFunctions();
            }
        },

        cleanChanges() {
            if (['bird_skf', 'hunting_swk_hunting'].includes(this.event.type)) {
                return this.evaluationChanges;
            }
            const shownInputs = this.evaluation.filter((input: any) => this.checkScopeRequirements(input));
            const nonRemovableScopes = [] as Array<String>;

            shownInputs.forEach((input: any) => {
                const nonRemovable = input.inputs.flatMap((block: any) => block.scope);

                nonRemovableScopes.push(...nonRemovable);
            });

            return this.evaluationChanges.filter((block: any) => nonRemovableScopes.includes(block.type))
        },

        async saveChanges() {
            if (this.event.type !== 'kennelvisit') {
                await this.registrations.addResult(
                    this.registration.id as string, 
                    this.currentType, 
                    [
                        {
                            comment: '',
                            type: 'no_show',
                            value: this.no_show_local,
                            value_text: this.no_show_local ? 'Kom ej till start' : 'Kom till start',
                            scope: null,
                            dynamic: null,
                        }
                    ]
                );
            }

            if (this.no_show_local === 1) {
                await this.registrations.MarkDone(this.registration.id as string, this.currentType);
                this.$router.push({
                    name: 'RegistrationView'
                });
                return;
            }
            
            const done = await this.registrations.isEvaluationDone(
                this.registration.id as string, 
                this.currentType
            );

            if (done) {
                await this.registrations.MarkIncomplete(
                    this.registration.id as string, 
                    this.currentType
                );
            }

            const valid = await this.checkMandatoryInputsAndMarkDone();
            const cleanedChanges = this.cleanChanges();

            await this.registrations.addResult(
                this.$route.params.regId as string, 
                this.currentType, 
                cleanedChanges
            );

            if (!valid.valid) {
                if (valid.missing) {
                    const missing = valid.missing.map((missingInput: any) => 
                        // @ts-ignore
                        skk.translations[missingInput.scope] ?? missingInput.scope
                    )
                    this.errorText = `${missing.join(',\n')}`;
                } else {
                    this.errorText = 'Kunde ej hitta tävlingstyp';
                }

                this.showError = true;
                return;
            }

            if (this.event.type === 'kennelvisit') {
                const markVisit = this.evaluationChanges.filter((c: any) => c.type === 'visit_completed') as any;
                if (markVisit.length) {
                    await this.registrations.markVisitComplete(
                        this.$route.params.regId as string, 
                        markVisit[0].value
                    ).catch((e) => {
                        console.log(e);
                    });
                }
            }

            let query = {}

            if (this.event.type === 'working_test') {
                query = { openPreview: 'true' }
            }

            this.$router.push({
                name: 'RegistrationView',
                query
            });
        },

        getRequiredValues(scope: any) {
            if (scope.requires) {
                const split = scope.requires.split(':');

                if (split.length > 2) {
                    const requiredScope = [] as string[];
                    const requiredValues = [] as string[];

                    split.forEach((entry: any, idx: number) => {
                        if ((idx % 2) === 0) {
                            requiredScope.push(entry);
                        } else {
                            requiredValues.push(entry);
                        }
                    });

                    const foundInput = requiredScope.map((reqScope: string, idx: number) => {
                        let found = this.evaluationChanges.find((c: any) => c.type === reqScope) as any;

                        if (!found) {
                            if (!this.values) {
                                return null;
                            }

                            found = this.values.find((v: any) => v.type === reqScope) as any;
                            if (!found) {
                                return null;
                            }
                        }

                        return {
                            input: found,
                            requiredValue: requiredValues[idx].split('|')
                        };
                    }).filter((v) => v);

                    return foundInput;
                }

                const requiredScope = split[0];
                const requiredValues = split[1].split('|');

                let found = this.evaluationChanges.find((c: any) => c.type === requiredScope) as any;

                if (!found && this.values) {
                    found = this.values.find((v: any) => v.type === requiredScope) as any;
                }

                if (found) {
                    return [{
                        input: found,
                        requiredValue: requiredValues
                    }];
                }

                return null;
            }
            return null;
        },

        // checkScopeFunctions() {
        //     if (this.compiledFunctions.includes('no_functions')) {
        //         return;
        //     }

        //     if (!this.compiledFunctions.length) {
        //         const sFunctions = this.evaluation.filter((input:any) => input.scope_function);

        //         if (!sFunctions.length) {
        //             this.compiledFunctions = ['no_functions'];
        //             return;
        //         }

        //         this.compiledFunctions = sFunctions.map((functions: {scope_function: string}) => {
        //             const steps = functions.scope_function.split('>');
        //             const affected_scope = steps[0];
        //             const result = steps[1];

        //             const condition = steps[2].match(/([A-z]+)\(/)?.[1] as string;
        //             let conditionalValue = [steps[2].match(/\((.+)\)/)?.[1]] as string[];

        //             if (/\|/.test(conditionalValue[0])) {
        //                 conditionalValue = conditionalValue[0].split('|');
        //             }
                    
        //             return {
        //                 affected_scope,
        //                 result,
        //                 condition,
        //                 conditionalValue
        //             }
        //         });
        //     }

        //     this.compiledFunctions.forEach((
        //         sFunction: {
        //             affected_scope: string,
        //             result: string,
        //             condition: string,
        //             conditionalValue: Array<string>
        //         }
        //     ) => {
        //         const cFound = this.evaluationChanges.filter(
        //             (c: any) => sFunction.conditionalValue.includes(c.type)
        //         ) as any

        //         if (cFound.length) {
        //             const conditionMet = cFound.filter((input: any) => input.value_text === sFunction.condition);

        //             if (conditionMet.length) {
        //                 const affectedIndex = 
        //                     this.evaluationChanges.findIndex((v: any) => v.type === sFunction.affected_scope);
                        
        //                 (this.evaluationChanges[affectedIndex] as any).value = (sFunction.result === 'true');
        //                 (this.evaluationChanges[affectedIndex] as any).value_text = sFunction.result;

        //                 console.log(this.values);
        //             }
        //         }
        //     })

        // },

        checkScopeRequirements(scope: any) {
            if (scope.requires) {
                const split = scope.requires.split(':');

                const checkIncludeGreaterThenOrLessThen = (reqValues: string[], input: any) => {
                    const greaterThenPassed = [] as Boolean[];
                    reqValues.forEach((v, i) => {
                        if (/>=?|=/.test(v)) {
                            greaterThenPassed[i] = v.split('>')[1] <= (Array.isArray(input.value) ? input.value.length : input.value);
                            return;
                        }
                        if (/<=?|=/.test(v)) {
                            greaterThenPassed[i] = v.split('<')[1] >= (Array.isArray(input.value) ? input.value.length : input.value);
                            return;
                        }
                        greaterThenPassed[i] = false;
                    })
                    return greaterThenPassed.every(Boolean);
                }

                
                const requiredScope = [] as string[];
                const requiredValues = [] as string[];

                split.forEach((entry: any, idx: number) => {
                    if ((idx % 2) === 0) {
                        requiredScope.push(entry);
                    } else {
                        requiredValues.push(entry);
                    }
                });
                
                return requiredScope.every((reqScope: string, idx: number) => {
                    const allChanges = [...this.values || [], ...this.evaluationChanges || []]
                    const allChangesKeepLastOccurrence = allChanges.filter(
                        (obj, index) =>
                            allChanges.findLastIndex((item) => item.type === obj.type) === index && typeof obj === 'object'
                    );

                    return allChangesKeepLastOccurrence.some((c: any) => {
                        if (c.type !== reqScope) {
                            return false;
                        }

                        const reqValues = requiredValues[idx].split('|');
                        const includesGreaterThenOrLessThen = reqValues.findIndex((v) => /[<>]=?|=/.test(v)) >= 0;

                        if (includesGreaterThenOrLessThen) {
                            return checkIncludeGreaterThenOrLessThen(reqValues, c);
                        }

                        return reqValues.includes(c.value.toString());
                    })
                });
            }
            return true;
        },

        checkRequirementsForMandatory(block: any) {
            if (!this.checkScopeRequirements(block)) {
                return false;
            }

            if (!block.requires) {
                return true;
            }

            const req = this.getRequiredValues(block);

            // @ts-ignore
            const reqFind = req?.find((r: any) => !r.requiredValue.includes(r.input.value));
            if (reqFind) {
                return false;
            }

            return true;
        },

        async checkMandatoryInputsAndMarkDone(): Promise<{valid: Boolean, missing: any}> {
            let mandatoryInputs = [] as Object[];
            let validForMark = true;

            switch (this.event.type)
            {
                case 'kennelvisit':
                    kennelvisit[this.currentType].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;

                case 'bird_skf':
                    switch (this.registration.breed) {
                        case 'BRAQUE FRANCAIS, TYPE PYRÉNÉES':
                        case 'BRETON':
                        case 'EPAGNEUL BRETON':
                            skf.breton[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            });
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'BRACCO ITALIANO':
                        case 'SPINONE ITALIANO':
                        case 'SPINONE':
                            if (this.registration.class.includes('EKL')) {
                                skf.brac_spin_ekl[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                });
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            }

                            skf.brac_spin[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            });
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'SLOVENSKÝ HRUBOSRSTY STAVAC (OHAR)':
                        case 'GRIFFON D\'ARRET À POIL DUR/KORTHALS':
                        case 'GRIFFON D\'ARRET A POIL DUR':
                        case 'WEIMARANER, KORTHÅRIG':
                        case 'WEIMARANER KURZHAARIG':
                        case 'WEIMARANER, LÅNGHÅRIG':
                        case 'WEIMARANER LANGHAARIG':
                            if (this.registration.class.includes('EKL') && this.event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Internationellt')) !== -1) {
                                skf.grif_weim_srhp_ekl_int[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                });
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            } else if (this.registration.class.includes('EKL') && this.event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Nationellt')) !== -1) {
                                skf.grif_weim_srhp_ekl_nat[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                });
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            }
                            skf.grif_weim_srhp[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            });
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        default:
                            if (this.registration.class.includes('EKL')) {
                                skf.drent_gdh_stab_other_ekl[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                });
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            }
                            skf.drent_gdh_stab_other[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            });
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                    }
                    break;

                case 'bird_fa':
                    switch (this.registration.class) {
                        case 'SKL endast Breton':
                        case 'SKL':
                            fa.standard.skl[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'UKL 9-24 mån': 
                            fa.standard.ukl[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'Apport':
                            fa.standard.apport[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'ÖKL över 9 mån':
                        default:
                            fa.standard.okl[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                    }
                    break;
                
                case 'fa_highstatus':
                    switch (this.registration.class) {
                        case 'SKL Internationell':
                        case 'SKL endast Breton':
                        case 'SKL':
                            fa.highstatus.skl[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                        case 'UKL 9-24 mån':
                        default:
                            fa.highstatus.ukl[this.currentType].forEach((block: any) => {
                                if (!this.checkRequirementsForMandatory(block)) {
                                    return;
                                }

                                const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                mandatoryInputs.push(mandatorys);
                            })
                            mandatoryInputs = mandatoryInputs.flat();
                            break;
                    }

                    break;
                case 'hunting_retriever_a':
                    const test_type = this.event.categories.find((category) => category.type === 'Provslag')?.value;

                    if (test_type === 'Nationellt') {
                        switch (this.registration.class) {
                            case 'ÖKL':
                                huntingretriever.type_a.okl_nat[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                })
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            case 'EKL':
                                huntingretriever.type_a.ekl_nat[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                })
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                            default:
                                huntingretriever.type_a.okl_nat[this.currentType].forEach((block: any) => {
                                    if (!this.checkRequirementsForMandatory(block)) {
                                        return;
                                    }

                                    const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                                    mandatoryInputs.push(mandatorys);
                                })
                                mandatoryInputs = mandatoryInputs.flat();
                                break;
                        }
                    } else {
                        huntingretriever.type_a.ekl_int[this.currentType].forEach((block: any) => {
                            if (!this.checkRequirementsForMandatory(block)) {
                                return;
                            }

                            const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                            mandatoryInputs.push(mandatorys);
                        })
                        mandatoryInputs = mandatoryInputs.flat();
                    }
                    break;
                case 'hunting_retriever_b':
                    huntingretriever.type_b[this.$route.params.type as string].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'hunting_spaniel_vatten':
                    mandatoryInputs = huntingspaniel.water.evaluation
                        .filter((block: any) => this.checkRequirementsForMandatory(block))
                        .flatMap((block: any) => ([
                            ...block.inputs.filter((input: any) => input.mandatory === true)
                        ]));
                    break;
                case 'hunting_spaniel_nyborjare':
                    mandatoryInputs = huntingspaniel.beginner.evaluation
                        .filter((block: any) => this.checkRequirementsForMandatory(block))
                        .flatMap((block: any) => ([
                            ...block.inputs.filter((input: any) => input.mandatory === true)
                        ]));
                    break;
                case 'hunting_spaniel_falt':
                    switch (this.registration.class) {
                        case 'Öppenklass Enkelsläpp':
                        case 'Öppenklass Parsläpp':
                            mandatoryInputs = huntingspaniel.okl.evaluation
                                .filter((block: any) => this.checkRequirementsForMandatory(block))
                                .flatMap((block: any) => ([
                                    ...block.inputs.filter((input: any) => input.mandatory === true)
                                ]));
                            break;
                        case 'Segrarklass enkelsläpp':
                        case 'Segrarklass parsläpp':
                        default:
                            mandatoryInputs = huntingspaniel.skl.evaluation
                                .filter((block: any) => this.checkRequirementsForMandatory(block))
                                .flatMap((block: any) => ([
                                    ...block.inputs.filter((input: any) => input.mandatory === true)
                                ]));
                            break;
                    }
                    break;
                case 'hunting_spaniel_wt':
                    mandatoryInputs = huntingspaniel.working.evaluation
                        .filter((block: any) => this.checkRequirementsForMandatory(block))
                        .flatMap((block: any) => ([
                            ...block.inputs.filter((input: any) => input.mandatory === true)
                        ]));
                    break;
                case 'bloodtracking':
                    bloodtracking.evaluation[this.currentType].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'working_test':
                    workingtest.evaluations.forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'chase':
                    chase.evaluation[this.currentType].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'chase_tax':
                    mandatoryInputs = taxchase.evaluation[this.currentType]
                        .filter((block: any) => this.checkRequirementsForMandatory(block))
                        .flatMap((block: any) => ([
                            ...block.inputs.filter((input: any) => input.mandatory === true)
                        ]));
                    break;
                case 'chase_basset':
                    bassetchase.evaluation[this.currentType].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'chase_adb':
                    adbchase.evaluation[this.currentType].forEach((block: any) => {
                        if (!this.checkRequirementsForMandatory(block)) {
                            return;
                        }

                        const mandatorys = block.inputs.filter((input: any) => input.mandatory === true);
                        mandatoryInputs.push(mandatorys);
                    })
                    mandatoryInputs = mandatoryInputs.flat();
                    break;
                case 'hunting_tjtk':
                case 'hunting_swk':
                    mandatoryInputs = hunting_swk[this.currentType]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                case 'hunting_swk_hunting':
                    switch (this.registration.class) {
                        case 'Öppen klass':
                            mandatoryInputs = hunting_swk_hunting.okl[this.currentType]
                                .flatMap((block: any) =>
                                    block.inputs
                                        .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                                );
                            break;
                        default: 
                            mandatoryInputs = hunting_swk_hunting.other[this.currentType]
                                .flatMap((block: any) =>
                                    block.inputs
                                        .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                                );
                            break;
                    }
                    break;    
                case 'hunting_swk_forest':
                    mandatoryInputs = hunting_swk_forest[this.currentType]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                case 'hunting_mock_trial':
                    mandatoryInputs = hunting_mock[this.currentType]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                case 'hunting_tolling':
                    mandatoryInputs = hunting_tolling[this.currentType]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                case 'hunting_tolling_practical':
                    mandatoryInputs = hunting_tolling_practical[this.currentType]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                case 'hunting_retriever_function':
                    mandatoryInputs = huntingfunction[this.$route.params.type as string]
                        .flatMap((block: any) =>
                            block.inputs
                                .filter((input: any) => input.mandatory === true && this.checkRequirementsForMandatory(block))
                        );
                    break;
                default:
                    return {valid: false, missing: null};
            }

            const missingInputs = [] as any;

            const pswmValues = (mandatoryInputs.find((v: any) => v.type === 'pswm') as any)?.values;
            if (pswmValues) {
                mandatoryInputs.push(...pswmValues);
            }

            mandatoryInputs.forEach((val: any) => {
                const cFound = this.evaluationChanges.find((c: any) => c.type === val.scope) as any;

                if (cFound && Array.isArray(cFound.value)) {
                    if (cFound.value.length === 0) {
                        validForMark = false;
                        missingInputs.push(val);
                    }


                    if (cFound.type === 'litter') {
                        cFound.value.forEach((v: any) => {
                            if (
                                v.litter_born === '' ||
                                v.litter_breed.length === 0 ||
                                (v.litter_num_female === 0 && v.litter_num_male === 0)
                            ) {
                                validForMark = false;
                                missingInputs.push(val);
                            }
                        });
                    }
                }

                if (!cFound) {
                    if (!this.values) {
                        validForMark = false;
                        missingInputs.push(val);
                        return;
                    }

                    const vFound = this.values.find((v: any) => v.type === val.scope) as any;

                    if (vFound && Array.isArray(vFound.value)) {
                        if (vFound.value.length === 0) {
                            validForMark = false;
                            missingInputs.push(val);
                        }

                        if (vFound.type === 'litter') {
                            vFound.value.forEach((v: any) => {
                                if (
                                    v.litter_born === '' ||
                                    v.litter_breed.length === 0 ||
                                    (v.litter_num_female === 0 && v.litter_num_male === 0)
                                ) {
                                    validForMark = false;
                                    missingInputs.push(val);
                                }
                            });
                        }
                    }

                    if (!vFound) {
                        validForMark = false;
                        missingInputs.push(val);
                    }
                }
            })

            if (!validForMark) {
                return {
                    valid: false, 
                    missing: missingInputs
                };
            }
            
            console.info(`${this.currentType}: Marked as Done`);
            await this.registrations.MarkDone(this.$route.params.regId as string, this.currentType);
            return { valid: true, missing: null };
        },

        getValues() {
            let value;

            if (this.evaluations.evaluations.length > 0 && this.evaluations.evaluations !== undefined)
            {
                const evaluation = this.evaluations.evaluations.find((e: any) => e.id === this.currentType);

                if (evaluation !== undefined) {
                    const map = new Map(Object.entries(evaluation));
                    value = Array.from(map.values());
                }
            }
            return value;
        }
    },
});

</script>

<style lang="less">
.evaluation {
  .__view {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
}

.points-input {
  .v-input__slot::before {
    border-color: transparent !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.section-title {
  border-bottom: 1px solid lighten(#000, 80%);
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 6px;
  .title {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
  }
  .tooltip {
    margin-top: -1px;
  }
}
</style>
