<template>
    <component 
        :is="currEvent.type"
        :curr-reg="currReg"
    />
</template>

<script lang="ts">
import {
    TypeEvent, 
    useEventStore, 
    TypeRegistration, 
    useRegistrationsStore 
} from '@/stores/store';

import { defineComponent } from 'vue'
import { useRoute } from 'vue-router';

// Views
import SKFView from '@/components/registration/skf/View.vue';
import FAView from '@/components/registration/fa/View.vue';
import ChaseView from '@/components/registration/chase/View.vue';
import BloodtrackingView from '@/components/registration/bloodtracking/View.vue';
import KennelvisitView from '@/components/registration/kennelvisit/View.vue';
import WorkingtestView from '@/components/registration/workingtest/View.vue';
import HuntingRetrieverView from '@/components/registration/hunting_retriever/View.vue';
import HuntingSWKView from '@/components/registration/hunting_swk/View.vue';
import HuntingSWKHuntingView from '@/components/registration/hunting_swk_hunting/View.vue';
import HuntingSWKForestView from '@/components/registration/hunting_swk_forest/View.vue';
import HuntingMockView from '@/components/registration/hunting_mock/View.vue';
import HuntingFunctionView from '@/components/registration/hunting_function/View.vue';
import HuntingTollingView from '@/components/registration/hunting_tolling/View.vue';

export default defineComponent({
    name: 'RegistrationInfo',
    components: {
        'bird_skf': SKFView,
        'bird_fa': FAView,
        'fa_highstatus': FAView,
        'chase': ChaseView,
        'chase_tax': ChaseView,
        'chase_basset': ChaseView,
        'chase_adb': ChaseView,
        'bloodtracking': BloodtrackingView,
        'kennelvisit': KennelvisitView,
        'working_test': WorkingtestView,
        'hunting_retriever_a': HuntingRetrieverView,
        'hunting_swk': HuntingSWKView,
        'hunting_swk_hunting': HuntingSWKHuntingView,
        'hunting_swk_forest': HuntingSWKForestView,
        'hunting_retriever_b': HuntingRetrieverView,
        'hunting_retriever_function': HuntingFunctionView,
        'hunting_spaniel_vatten': HuntingRetrieverView,
        'hunting_spaniel_nyborjare': HuntingRetrieverView,
        'hunting_spaniel_falt': HuntingRetrieverView,
        'hunting_spaniel_wt': HuntingRetrieverView,
        'hunting_mock_trial': HuntingMockView,
        'hunting_tolling': HuntingTollingView,
        'hunting_tolling_practical': HuntingTollingView,
        'hunting_tjtk': HuntingSWKView,
    },
    props: {
        currentEvent: { type: Object, default: () => {} },
        currentRegistration: { type: Object, default: () => {} }
    },
    emits: ['update:currentEvent', 'update:currentRegistration'],
    async setup () {
        const route = useRoute();
        const events = useEventStore();
        const registrations = useRegistrationsStore();
        
        let currEvent = {} as TypeEvent;
        let currReg = {} as TypeRegistration;

        if (events.events.length === 0 ||
            registrations.registrations.length === 0)
        {
            await events.init();
            await registrations.init(events.getEventByID(route.params.eventId as string));
        }
        
        currEvent = events.getEventByID(route.params.eventId as string);
        currReg = registrations.getRegistrationByID(route.params.regId as string);

        if (currEvent.type === 'kennelvisit') {
            currReg = await registrations.getKennelData(currReg);
        }

        return {
            currEvent,
            currReg,
        }
    },

    created() {
        this.$emit('update:currentEvent', this.currEvent);
        this.$emit('update:currentRegistration', this.currReg);
    }
})
</script>

<style lang="less">
.category-span {
    color: rgb(159,159,159);
}

.info-col {
    h4 {
        margin-bottom: 10px;
    }
}
</style>
